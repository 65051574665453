import { render, staticRenderFns } from "./ProformaFreightPop.vue?vue&type=template&id=3e4cb622"
import script from "./ProformaFreightPop.vue?vue&type=script&lang=js"
export * from "./ProformaFreightPop.vue?vue&type=script&lang=js"
import style0 from "./ProformaFreightPop.vue?vue&type=style&index=0&id=3e4cb622&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/kmtcadmin/myagent/_work/15/s/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3e4cb622')) {
      api.createRecord('3e4cb622', component.options)
    } else {
      api.reload('3e4cb622', component.options)
    }
    module.hot.accept("./ProformaFreightPop.vue?vue&type=template&id=3e4cb622", function () {
      api.rerender('3e4cb622', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/pdf/popup/ProformaFreightPop.vue"
export default component.exports